import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArchiveHeader from "../components/header-components/archive-header/archiveHeader"
import VideoProject from "../components/projectComponents/videoProject/videoProject"
import PhotoProjectPage from "../components/projectComponents/photo/photoProject"
import { ProjectContainer } from "./styled-comps/ProjectContainer"
import { ProjectInfo } from "./styled-comps/ProjectInfo"

class ArchivePost extends React.Component {
  render() {
    const post = this.props.data.sanityPost
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const { projectType, photoSet, videoSet, title, _rawBody, _rawDescription } = post
    const archive = true
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={title}
          // description={post.frontmatter.description || post.excerpt}
        />
        <ProjectContainer>
          {this.props.location.state ? (
            <ArchiveHeader
              local={this.props.location.state.fromPres}
              pathBack={this.props.location.state.pathBack}
              title={post.title}
              previous={previous}
              next={next}
            />
          ) : (
            <ArchiveHeader
              title={post.title}
              previous={previous}
              next={next}
              archive={archive}
            />
          )}
          <ProjectInfo>
            {projectType === "video" ? (
              <VideoProject title={title} videos={videoSet} text={_rawBody} description={_rawDescription} />
            ) : (
              <PhotoProjectPage
                title={title}
                text={_rawBody}
                images={photoSet}
                description={_rawDescription}
              />
            )}
          </ProjectInfo>
        </ProjectContainer>
      </Layout>
    )
  }
}

export default ArchivePost

export const pageQuery = graphql`
  query ArchivePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    sanityPost(slug: { current: { eq: $slug } }, active: { eq: false }) {
      title
      projectType
      videoSet
      photoSet {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawDescription
      active
      _rawBody
    }
  }
`
