import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { ProjectPageHeader } from "../ProjectPageHeader"
import { ProjectHeaderContainer } from "../ProjectHeaderContainer"
import HeaderLogo from "../../SVGs/headerLogo"
import { colors } from "../../colors/colors"

const LogoHolder = styled(Link)`
  height: 75px;
  width: 75px;
  grid-column: 1/2;
  text-decoration: none;

  @media (max-width: 650px) {
    grid-row: 1/3;
  }
`
const Titles = styled.div`
  grid-column: 2/3;
  text-align: center;

  @media (max-width: 650px) {
    grid-column: 2/4;
    grid-row: 1/2;
    justify-self: center;
    > h1 {
      font-size: 20px;
    }
  }
`
const PathBack = styled(Link)`
  grid-column: 3/4;
  text-decoration: none;
  font-size: 20px;
  font-style: italic;
  padding: 15px 2px;
  color: ${colors.white};
  display: inline-block;
  &:hover {
    color: ${colors.brightRed};
  }

  @media (max-width: 650px) {
    grid-column: 2/3;
    grid-row: 2/3;
    justify-self: end;
    padding-right: 10px;
  }

  @media (max-width: 812px) {
    font-size: 18px;
  }
`
const ArchivePagesHeader = ({ title, local, pathBack, archive }) => {
  return (
    <ProjectPageHeader role="header">
      <ProjectHeaderContainer>
        {archive === true || !local ? (
          <>
            <LogoHolder to={"/home"}>
              <HeaderLogo />
            </LogoHolder>
            <Titles>
              <h1>{title}</h1>
            </Titles>
          </>
        ) : (
          <>
            <LogoHolder>
              <HeaderLogo />
            </LogoHolder>
            <Titles>
              <h1>{title}</h1>
            </Titles>
            <PathBack to={pathBack}>Back to Presentation</PathBack>
          </>
        )}
      </ProjectHeaderContainer>
    </ProjectPageHeader>
  )
}

export default ArchivePagesHeader
